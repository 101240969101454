// nuxt-ui/middleware/notFoundQueens.ts
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";

export default defineNuxtRouteMiddleware(() => {
  const { isQueens } = useWebContext();

  if (isQueens) {
    throw createError({ statusCode: 404 });
  }
});
